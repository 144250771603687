$green: #679B13;
$yellow: #E1DA25;
$purple: #CEBDF4;
$beige: #F2E9DC;
$beigeAccent: #EBD7B8;
$brown: #4E3000;
$brownShadow: #7e5b2f;

$text: $brown;
$textShadow: $brownShadow;
$bg: $green;
$bg2: $beige;

$gridBase: 1vh;

body {
  letter-spacing: 0.4px;
  word-spacing: 0.7px;
  font-family: 'Aeonik', sans-serif;
  color: $text;
  text-size-adjust: none;
  background: $bg;
  font-weight: 400;
}

button {
  font-family: 'Aeonik', sans-serif;
  margin: 0;
  border: 0;
  padding: 0;
  background: transparent;
}

input {
  font-family: 'Aeonik', sans-serif;
  color: $text;
}

input::placeholder {
  color: $textShadow;
}

h3 {
  font-family: 'Aeonik', sans-serif;
  font-weight: 700;
  margin: 0;
}

h4 {
  font-weight: 500;
}

.bold {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

@mixin list-item($color: $green) {
  font-size: 100%;
  border: $color 1px solid;
  border-radius: 1 * $gridBase;
  padding: 0.25 * $gridBase $gridBase;
  color: $color;
}

