@import "./Base.scss";

$mobile-ratio: 1.3;
.ketocoach-app {
  display: flex;
  justify-content: center;
  background: $bg;
}

.ketocoach-mobile {
  height: 100svh;
  @media (max-aspect-ratio: 1/$mobile-ratio) {
    width: 100dvw;
  }
  @media (min-aspect-ratio: 1/$mobile-ratio) {
    aspect-ratio: 1 / $mobile-ratio;
  }
  background: $bg;
}

.ketocoach-qr {
  @media (max-aspect-ratio: 1 / $mobile-ratio) {
    display: none;
  }
  width: calc(50dvw - 50dvh / $mobile-ratio);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 100%;
    width: fit-content;
    padding: 10%;
    > img {
      max-width: 15dvw;
    }
  }
}
