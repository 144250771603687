@import "Base";

.quick-action {
  @include list-item;
  background: transparent;
  height: 4 * $gridBase;
  margin: 0 0.25*$gridBase 1px 0.25*$gridBase;
}
.quick-action.game {
  font-size: 125%;
  border: none;
  background: $beigeAccent;
}

.quick-action:first-child {
  margin-left: 0;
}

.quick-action:last-child {
  margin-right: 0;
}
