@import "../Base";

.choices {

}

.choice {
  @include list-item;
  margin: 0.5 * $gridBase 0;

  &-label {
    font-weight: 700;
  }
}
