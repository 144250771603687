.debug {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;

  &-open {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 1001;
    background: wheat;
  }

  &-context {
    display: none;
    background: wheat;
    position: absolute;
    top: 0;
    right: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 1000;

    > div {
      padding: 5dvh;
    }
  }

  &-context.open {
    display: block;
  }
}
