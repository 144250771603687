@import "Base";
.chat-message-reaction-popover {
  display: flex;
  flex-direction: row;
  button {
    font-size: 1.25rem;
  }

  div.line {
    width: 1px;
    background: $brown;
  }
}
