@import "Base";

.send {
  position: relative;

  > input {
    box-sizing: border-box;
    width: 100%;
    height: 6*$gridBase;
    padding: $gridBase 6*$gridBase $gridBase 2*$gridBase;
    font-size: 100%;
    margin: 0;
    border-radius: 1.5* $gridBase;
    border: 1px solid $green;
    background: $bg2;
  }

  &-button {
    position: absolute;
    right: 0.75*$gridBase;
    top: 0;
    height: 100%;

    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      > button {
        height: 80%;

        > img {
          height: 100%;
        }
      }
    }
  }
}
