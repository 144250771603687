@import "Base";

.chat-message {
  position: relative;
  display: grid;
  grid-template-columns: 4.5 * $gridBase auto;
  grid-template-rows: auto auto;
  width: 100%;

  &-profile-avatar {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    padding: 0 1.25 * $gridBase 0 0.25*$gridBase;

    > div {
      display: flex;
      justify-content: center;

      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &-profile-name {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;

    > div {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;

      > h3 {
        font-size: 100%;
      }
    }
  }

  &-content {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;

    > div {
      > p {
        margin-top: 0;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 0.5rem;
          text-indent: -1.33rem;
        }
      }

      a {
        text-decoration: none;
        color: $green;
        font-weight: 700;
      }
    }

  }

  &-bottom-anchor {
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    visibility: hidden;
  }
}

