@import "Base";

.chat {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: $beige;
  border-radius: 2 * $gridBase;
  border: solid 2px $yellow;
  box-shadow: 0 0 $gridBase $gridBase rgba(0, 0, 0, 0.25);
  padding: 2 * $gridBase 1 * $gridBase 1 * $gridBase 1 * $gridBase;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  &-messages {
    margin-right: -0.75 * $gridBase;
    position: relative;
    flex-grow: 1;

    > div {
      position: absolute;
      width: 100%;
      height: 100%;

      > div {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    &-content {
      box-sizing: border-box;
      padding-right: 2 * $gridBase;
      width: 100%;
      height: 100%;

      .simplebar-track.simplebar-vertical {
        width: 1.25* $gridBase;

        .simplebar-scrollbar:before {
          background-color: $brown;
        }
      }
    }
    &-content.scrollbar-hidden .simplebar-track {
      display: none;
    }

    &-curtain {
      display: none;
      position: absolute;
      z-index: 1;
      top: -1px;
      width: 100%;
      height: 3 * $gridBase;
      background: linear-gradient(0, transparent, $beige);

      &.visible {
        display: block;
      }
    }

  }

  &-block {
    margin-top: 0.5*$gridBase;
  }

  &-send {
    margin-top: 0.5* $gridBase;
  }
}
