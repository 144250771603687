@import "Base";

$borders: 1.5 * $gridBase;
.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  padding: $borders;

  &-header {
    height: 5lvh;
    padding: 0 0 $borders 0;

    > div {
      height: 100%;
      width: 100%;
      position: relative;
      box-sizing: border-box;

    }


    &-logo {
      height: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-my-account {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      display: none;

      > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        img {
          height: 40%;
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }


    }

    &-bacoins {
      position: absolute;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      display: flex;
      align-items: center;

      > span {
        height: 50%;
        color: white;
        font-size: 150%;
      }
    }

    &-coach-avatar {
      position: absolute;
      top: -40%;
      height: 300%;
      left: 67%;
      z-index: 10;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &-chat {
    height: 95%;
  }
}
