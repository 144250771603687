@import "Base";

.quick-actions-group {

  &-wrapper {
    position: relative;

    &-curtain-right {
      &.visible {
        display: block;
      }

      display: none;
      position: absolute;
      z-index: 10;
      width: 1*$gridBase;
      height: 100%;
      right: -1px;
      top: 0;
      background: linear-gradient(90deg, transparent, $beige);
    }

    &-curtain-left {
      &.visible {
        display: block;
      }

      display: none;
      position: absolute;
      z-index: 10;
      width: 1*$gridBase;
      left: -1px;
      top: 0;
      height: 100%;
      background: linear-gradient(270deg, transparent, $beige);
    }
  }

  &-actions {
    width: max-content;

    &-scroll-animation {
      animation: scroll-animation var(--animation-speed) linear;
      @keyframes scroll-animation {
        100% {
          transform: translateX(-100%);
        }
      }
    }
  }

  .simplebar-track {
    display: none;
  }
}
